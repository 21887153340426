import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DEFAULT_META_CONTENTS } from '@core/constants';
import { ConfigState } from '@core/data-access/states/config.state';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SeoService {
	constructor(
		private readonly metaService: Meta,
		private readonly titleService: Title,
		private readonly configState: ConfigState,
		private readonly router: Router
	) {}

	setSeo(content: { title: string; description?: string; url?: string; image?: string; includeTitleSuffix?: boolean }): void {
		// First remove all meta tags to eliminate checking for exiting ones.
		// Also benefit from deleting all is not to add meta tag when it is not provided for that page.
		this.removeAllMetaTags();

		// Set new meta tags
		this.setTitle(content.title, content?.includeTitleSuffix);
		this.setUrl(content.url);
		this.setImage(content.image);
		this.setType(DEFAULT_META_CONTENTS.type);
		this.setSiteName(DEFAULT_META_CONTENTS.siteName);

		// Optional meta tags
		if (content.description) {
			this.setDescription(content.description);
		}
	}

	robotsNoindex(): void {
		this.metaService.addTag({
			name: 'robots',
			content: 'noindex, nofollow',
		});
	}

	private setImage(image: string | undefined): void {
		this.metaService.addTag({ property: 'og:image', content: image || DEFAULT_META_CONTENTS.image });
	}

	private setTitle(title: string | undefined, includeTitleSuffix: boolean): void {
		const TRANSLATIONS = this.configState.getTranslations();

		// Use the translated title if available; otherwise, use the provided title or a default title.
		const translatedTitle = TRANSLATIONS[title] || title || TRANSLATIONS['n3158'];

		const pageTitle = `${translatedTitle} ${includeTitleSuffix ? `| FindQo.ie` : ``}`;

		this.titleService.setTitle(pageTitle);
		this.metaService.addTag({
			property: 'og:title',
			content: pageTitle,
		});
	}

	private setDescription(description: string): void {
		this.metaService.addTag({
			name: 'description',
			content: description,
		});
		this.metaService.addTag({
			property: 'og:description',
			content: description,
		});
	}

	private setUrl(url: string): void {
		this.metaService.addTag({
			property: 'og:url',
			content: url || `${environment.appUrl}${this.router.url}`.split('?')[0],
		});
	}

	private setType(type: string | undefined): void {
		this.metaService.addTag({ property: 'og:type', content: type ?? 'website' });
	}

	private setSiteName(siteName: string): void {
		this.metaService.addTag({
			property: 'og:site_name',
			content: siteName,
		});
	}

	private removeAllMetaTags(): void {
		this.metaService.removeTag('property="og:title"');
		this.metaService.removeTag('name="description"');
		this.metaService.removeTag('property="og:description"');
		this.metaService.removeTag('property="og:url"');
		this.metaService.removeTag('property="og:site_name"');
		this.metaService.removeTag('property="og:image"');
		this.metaService.removeTag('property="og:image:width"');
		this.metaService.removeTag('property="og:image:height"');
		this.metaService.removeTag('property="og:image:type"');
		this.metaService.removeTag('property="og:type"');
		this.metaService.removeTag('property="og:locale"');
		this.metaService.removeTag('name="twitter:card"');
	}
}
